

























































































































































































import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { DateTime } from 'luxon';
import {
  BlockchainNetworkCurrencyLabel,
  BlockchainNetworkURL,
  transactionTypeMapConfig,
  TransactionTypeWithNetworkNaming,
  CustodyTransactionData,
  CustodyTransactionRequestParams,
  VuetifyTableOptions,
  MerchantUserWallet
} from '@/api/schema';
import { textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import formatDate from '@/helpers/formatDate';
import { roundNumber } from '@/helpers/roundNumber';
import {
  getMerchantUsers,
  getMerchantUserTransactions
} from '@/api/SuperadminCustody';

const defaultDateData = {
  from: DateTime.utc().minus({ months: 1 }).toFormat('yyyy-LL-dd'),
  to: DateTime.utc().toFormat('yyyy-LL-dd')
};

export default {
  name: 'SuperAdminMerchantWalletHistoryView',
  components: {
    VExtendedDataPicker
  },
  data(): any {
    return {
      BlockchainNetworkCurrencyLabel,
      BlockchainNetworkURL,
      transactionTypeMapConfig,
      walletDataIsLoading: false,
      transactionsIsLoading: false,
      userWallet: {} as MerchantUserWallet,
      filterMenu: false,
      searchPhrase: '',
      searchFilter: 'from',
      searchFilterOptions: [
        { text: 'Wallet From', value: 'from' },
        { text: 'Wallet To', value: 'to' }
      ],
      dates: { ...defaultDateData },
      transactionType: '',
      TransactionTypeWithNetworkNaming,
      transactionsTypeOptions: Object.values(transactionTypeMapConfig).filter(
        (item) => item.value !== 'TRANSFER'
      ),
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'date', width: '150' },
        { text: 'Transaction Type', value: 'type', width: '18%' },
        { text: 'Wallet From', value: 'from', width: '150' },
        { text: 'Wallet To', value: 'to', width: '150' },
        { text: 'Token Amount', value: 'amount' },
        { text: 'Fee', value: 'fee' },
        { text: 'Transaction Hash', value: 'hash', width: '200' }
      ],
      total: 0,
      pageData: {
        page: 1,
        itemsPerPage: 10
      },
      transactions: [] as CustodyTransactionData[]
    };
  },
  computed: {
    currentPage(): number {
      return this.pageData.page - 1;
    }
  },
  watch: {
    searchPhrase(newVal: string): void {
      if (!newVal) {
        this.loadTransactions();
      }
    }
  },
  created(): void {
    this.loadUserInfo();
  },
  methods: {
    textOverflowMiddle,
    copyToClipBoard,
    formatDate,
    roundNumber,
    async loadUserInfo(): Promise<void> {
      try {
        this.walletDataIsLoading = true;

        const params = {
          page: 0,
          size: 1000,
          userId: this.$route.params.userId
        };

        const res = await getMerchantUsers(
          this.$route.params.merchantId,
          params
        );

        this.userWallet = res.content[0];
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.walletDataIsLoading = false;
      }
    },
    async loadTransactions(): Promise<void> {
      try {
        this.transactionsIsLoading = true;

        const params: CustodyTransactionRequestParams = {
          page: this.searchPhrase ? 0 : this.currentPage,
          size: this.pageData.itemsPerPage,
          ...(this.transactionType && {
            type: this.transactionType
          }),
          ...(this.searchPhrase && {
            [this.searchFilter]: this.searchPhrase
          }),
          fromDate: this.dates.from,
          toDate: this.dates.to
        };

        const res = await getMerchantUserTransactions(
          this.$route.params.userId,
          params
        );

        this.transactions = res.content;
        this.total = res.totalElements;
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.transactionsIsLoading = false;
      }
    },
    changeDateHandler(value: string[]): void {
      this.dates = value;
      this.loadTransactions();
    },
    updateOptionsHandler(options: VuetifyTableOptions): void {
      this.pageData = options;
      this.loadTransactions();
    },
    setTypeFilter(action: 'clear' | 'apply' = 'apply'): void {
      if (action === 'clear') {
        this.transactionType = '';
      }

      this.filterMenu = false;
      this.loadTransactions();
    }
  }
};
